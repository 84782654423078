.about {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
    & .tr1 {
        position: absolute;
        left: 0;
        top: -30%;
        z-index: -1;
    }

    & .tr2 {
        position: absolute;
        right:0;
        top: -80%;
        z-index: -1;
    }

    & .list {
        max-width: 798px;
        width: 100%;
        padding: 0 2.5%;

        & ul li {
            color: #1F0441;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
        }
    }
}

@media(max-width: 425px) {
    
    .tr1 img {
        width: 300px !important;
    }
}