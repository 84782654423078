.hero {
    width: 100%;
    // min-height: 100vh;
    height: 900px;
    background:  url('../../assets/main.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .hero-content {
    max-width: 742px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .hero h1 {
    font-size: 72px;
    font-weight: 700;
    line-height: 90px;
    text-align: left;
    margin-bottom: 30px;
    font-family: "Nurito", sans-serif;
  }

  .btn {
    font-family: "Roboto", sans-serif;
    font-weight: 800;
  }
  
  .btn:hover {
    background-color: #3700b3;
  }

@media(max-width: 850px) {
    .hero-text h1 {
        font-size: 40px;
    }
}

@media(max-width: 650px) {
    .hero-text h1 {
        font-size: 30px;
        max-width: 400px;
        margin: auto;
    }

    .hero-text p {
        font-size: 14px;
        margin: 15px auto 30px;
    }
}


@media(max-width: 767px) {
  .hero-content h1 {
      font-size: 50px;
      line-height: 70px;
  }
}