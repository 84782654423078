.news {

    & .newsContainer {
        max-width: 1296px;
        width: 100%;
        background-color: #F6F6F6;
        margin-top: 150px;
        margin: 0 auto;

        & .newsItem {
            max-width: 856px ;
            width: 100%;
            margin: 0 auto 50px;
            padding: 15px;
            box-shadow: 0px 2px 7px 0px #05034A59;
            min-height: 937px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            & img {
                max-width: 808px;
                width: 100%;
                max-height: 423px;
                height: 100%;
            }

            & h2 {
                width: 100%;
                color: #7360F9;
                font-size: 24px;
                font-weight: bold;
                line-height: 36px;
                font-family: "Nurito", sans-serif;
                font-weight: 700;
            }

            & .date {
                margin-top: 30px;
                color: #1F0441;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                width: 100%;
                font-family: "Nurito", sans-serif;
                font-weight: 400;
            }

            & .text {
                margin: 30px 0 50px;
                color: #1F0441;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                font-family: "Nurito", sans-serif;
                font-weight: 400;
            }

            & .bntBlock {
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                & button {
                    color: #7360F9;
                    font-size: 16px;
                    font-weight: 800;
                    line-height: 24px;
                    letter-spacing: 5%;
                    border: 1px solid #7360F9;
                    border-radius: 30px;
                    padding: 10px 25px;
                    cursor: pointer;
                    font-family: "Roboto", sans-serif;
                    font-weight: 800;
                }
            }
           
        }
    }
}

@media(max-width: 880px) {
    .newsContainer {

        & .newsItem {
            max-width: 700px !important;
       
        }
    }
}

@media(max-width: 710px) {
    .newsContainer {

        & .newsItem {
            max-width: 500px !important;
       
        }
    }
}


@media(max-width: 525px) {
    .newsContainer {

        & .newsItem {
            max-width: 350px !important;
       
        }
    }
}


