.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
}

.modal i {
    color: rgb(100, 100, 100);
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.modal-content {
    max-width: 559px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    background: rgb(249, 250, 251);
    transform: translate(-50%, -50%);
    padding: 1rem;
    border-radius: 15px;
}

.modal-content h1 {
    text-align: center;
}

.btns button {
    width: 100%;
    margin: 0.3rem;
    padding: 10px;
    font-weight: 600;
    border-radius: 6px;
    border: 1px solid;
}

.accept {
    background-color: rgb(42, 102, 188);
    color: #fff;
}

.accept:hover {
    background-color: rgb(14, 71, 149);
    transition: .3s;
    cursor: pointer;
}

.reject {
    color: rgb(100, 100, 100);
}

.reject:hover {
    background-color: rgb(194, 192, 192);
    transition: .3s;
    cursor: pointer;
}

label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1F0441;
}

input {
    max-width: 464px;
    width: 100%;
    height: 56px;
    gap: 30px;
    border: 1px solid #1F0441
}

form {
    display: flex;
    width: 525px;
    justify-content: center;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 1px;

    &:focus {
        border-color: #a78bfa;
        box-shadow: 0 0 0 4px rgba(167, 139, 250, 0.2);
    } 
}

textarea {
    height: 176px;
    margin-bottom: 30px;
    border: 1px solid #1F0441
}
.btns {
    display: flex;
    align-items: center;
    justify-content: center;

        & button {
        width: 145px !important;
       
    }
    
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #1F0441;
  border-radius: 1px;
  outline: none;
  transition: border-color 0.3s ease; 

  &:focus {
    border-color: #7360F9; 
  }
}

@media(max-width: 588px) {
    .modal-content {
       width: 400px;

       & form {
            width: 300px;
            margin: 0 auto;
            padding: 0 5px;
       }
    }
}

@media(max-width: 425px) {
    .modal-content {
       width: 300px;
       

       & form {
            width: 250px;
            margin: 0 auto;
            padding: 0 5px;
       }
    }
}