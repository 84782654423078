.title {
    text-align: center;
    color: #1F0441;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin: 70px 0 30px;

    & h2 {
        font-size: 32px;
        color: #7360F9;
        margin-top: 5px;
        text-transform: none;
        margin-bottom: 80px;
        font-family: "Nurito", sans-serif;
        font-weight: 700;
    }

    & p {
        font-family: "Nurito", sans-serif;
        font-weight: 600;
    }
}