.dev {
   margin-bottom: 100px;
    & .upDown {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #1F0441;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;

        & .up {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 30px;

            & p {
                width: 196px;
                text-align: center;
                padding: 10px 10px;
                margin: 5px 0;
                position: relative;
                
                border-left: 5px solid;
                border-image: linear-gradient(to bottom, #7360F9, #97D9E6) 1;

                border-top: 1px solid red;
                border-bottom: 1px solid red;
      
                border-right: 1px solid #1F0441;
                box-shadow: 0px 2px 7px 0px #05034A59;

                font-family: "Roboto", sans-serif;
                font-weight: 400;
            }
        }

        & .down {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;

            & p {
                width: 196px;
                border-width: 1px;
                border-style: solid;
                border-image: linear-gradient(to bottom, #7360F9, #97D9E6) 1 100%;
                text-align: center;
                padding: 10px 10px;

                border-left: 5px solid;
                border-image: linear-gradient(to bottom, #7360F9, #97D9E6) 1;

                border-top: 1px solid red;
                border-bottom: 1px solid red;

                border-right: 1px solid #1F0441;
                box-shadow: 0px 2px 7px 0px #05034A59;

   
     
            }
        }
    }
}


@media (max-width: 1023px) {

   .dev {

    & .upDown {

        .up {
            gap: 30px;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        .down {
            gap: 30px;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
   }
    
}

    