.testimonials {
    margin: 80px auto;
    position: relative;
    
    & .unite, .provide, .create {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        & .treeText {
            display: flex;
            width: 100%; /* Займає всю ширину */
            align-items: center;
            justify-content: center;
            padding-left: 11%;
            margin-bottom: 50px;
        }
        

        & .text {
            max-width: 100%; /* Займає всю ширину */
            width: 100%;
            align-items: center;

            & h2 {
                font-size: 36px;
                font-weight: bold;
                line-height: 54px;
                color: #7360F9;
                margin-bottom: 20px;
                font-family: "Nurito", sans-serif;
                font-weight: 700;
            }

            & p {
                color: #1F0441;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                font-family: "Nurito", sans-serif;
                font-weight: 600;
            }
        }

        & .treeBlock {
            position: absolute;
            left: 5%;
            top: 1%;
            z-index: -1;
        }

        & .weUn img {
            max-width: 635px;
            width: 635px;
            height: 426px;

        }
    }

    .treeBlock2 {
        position: absolute;
        z-index: -1;
    }

    .treeBlock3 {
        position: absolute;
        z-index: -1;
        left: 5%;
    }
}

@media (max-width: 1270px) {
    .unite {
        flex-direction: column;

        & .treeText {
            padding: 0 !important;
            width: 100%; /* Займає всю ширину */
        }

        & .text {
            max-width: 1000px;
            width: 100%; /* Займає всю ширину */
        }
    }    

    .provide {
        flex-direction: column-reverse;

        & .treeText {
            padding: 0 !important;
        }
    }

    .create {
        flex-direction: column;

        & .treeText {
            padding: 0 !important;
        }
    }
   
}
  

@media(max-width: 767px) {
    
     .weUn img {
        max-width: 635px;
        width: 100% !important;
        height: auto !important;
    }
}
    
