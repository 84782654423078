.part {
    & .partBlock {
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        grid-gap: 15px;
        place-items: center;

        & img {
            width: 195px;
        }

        & :nth-child(3) {
            width: 104px;
        }
    }
}

@media(max-width: 767px) {
    
    .partBlock {
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        
    }
}