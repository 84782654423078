.programs {
    display: flex;
    flex-direction: column;
    height: 600px;
    justify-content: space-around;
    align-items: flex-start;
    margin: 80px auto;
    width: 90%;
    position: relative;
    margin-bottom: 200px;
  
    .program {
      display: flex;
      align-items: center;
      text-align: center;
      position: relative;
  
      &:nth-child(1) {
        position: absolute;
        top: 0;
        left: 100;
      }
  
      &:nth-child(2) {
        position: absolute;
        top: 10;
        left: 15%;
        transform: translateY(-15%);
      }
  
      &:nth-child(3) {
        position: absolute;
        top: 50;
        left: 40%;
        transform: translateY(75%);
      }
  
      &:nth-child(4) {
          position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateY(50%);

        & p {
            width: 415px;
        }
      }
  
      img {
        max-width: 150px;
        width: 100%;
        margin-bottom: 20px;
      }
  
      p {
        color: #7360F9;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0;
        margin-right: 100px;
        font-family: "Nurito", sans-serif;
        font-weight: bold;
        
      }
    }
  }

  @media(max-width: 1165px) {

    .programs {

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      
      flex-direction: inherit;


      & .program {
        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
           all: unset;
        }

        p {
          width: 300px !important;
          margin: 0;
          text-align: center;
        }
        
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column !important;
        
      }
   

  }
}

@media (max-width: 1111px) {
    .programs {
      margin: 0px auto; 
    }
}

@media(max-width: 767px) {
  .programs {
    justify-content: center;
    margin-bottom: 250px;
  }
}
  