.services {

    .servicesBlock {
        display: flex;
        padding: 0 3%;
        justify-content: center;
        flex-direction: column;

        & .item {
            display: flex;
            gap: 50px;
            margin-bottom: 30px;
            

            & img {
                width: 118px;
                margin-bottom: 15px;
               
            }

            & h4 {
                color: #1F0441;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                font-family: "Nurito", sans-serif;
                font-weight: 600;
            }

            & h5 {
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                color: #1F0441;
                margin-bottom: 30px;
                font-family: "Roboto", sans-serif;
                font-weight: 400;
            }

            & p {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #1F0441;
                margin-bottom: 30px;
                font-family: "Roboto", sans-serif;
                font-weight: 400;
            }

            & .imgBlock {
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;
                width: 305px;
                height: 211px;
            }

            // & .textBlock {
                
            // } 

        }
    }
}

@media(max-width: 767px) {
    
    .item {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .imgBlock {
        height: auto !important;
    }
}