@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

body {
  background: #f5f5f5;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
 
}

a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}

.container {
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  margin: 0 auto;
}

.btn {
  background: #7360F9;
  color: #F6F6F6;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn img {
  width: 20px;
  margin-left: 10px;
}

.btn.dark-btn {
  background: #212ea0;
  color: #fff;
}

.cookieModal {
  width: 500px;
  padding: 40px 20px;
  position: fixed;
  bottom: 2%;
  left: 10%;
  background: #000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  z-index: 999;

  & p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 30px;
  }

  & .btnBlock button {
    background-color: #fff;
    padding: 10px 30px;
    margin-left: 40px;
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
}

.arrow {
  font-size: 30px;
  position: absolute;
  right: 5%;
  top: 500%;
  cursor: pointer;
}

@media(max-width: 1200px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .btn {
    padding: 14px 22px;
  }

  .arrow {
    display: none;
  }
}

.navbar__con {
  margin-bottom: 110px;
}

