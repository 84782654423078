.newsStory {
    height: 100vh;
    position: relative;

    & .newsDetail {
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        & h1 {
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            line-height: 54px;
            color: #7360F9;
            font-family: "Nurito", sans-serif;
            font-weight: 700;
        }

        & .date {
            color: #1F0441;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            width: 100%;
            font-family: "Nurito", sans-serif;
            font-weight: 400;
        }

        & .text {
            color: #1F0441;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            margin-bottom: 70px;
            font-family: "Nurito", sans-serif;
            font-weight: 400;
        }

        & img {
            max-width: 800px;
            width: 100%;
        }
    }

    .footer {
        width: 100%;
    }
}