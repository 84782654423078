.clusterContainer {
    margin-bottom: 150px;
}
.cluster {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 130px;
    
    & .left {
       

        & img {
            position: absolute;
            top: 13.5%;
            left: 17%;
        }

        & p {
            position: absolute;
            top: 70%;
            left: 22%;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            color: #F6F6F6;
            font-family: "Nurito", sans-serif;
            font-weight: 600;
        }
    }

    & .center {

        & img {
            position: absolute;
            top: 13.5%;
            z-index: -1;
            left: 41%;
        }

        
        & p {
            position: absolute;
            top: 70%;
            left: 47%;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            color: #F6F6F6;
            font-family: "Nurito", sans-serif;
            font-weight: 600;
        }
    }

    & .right {

        & img {
            position: absolute;
            top: 13.5%;
            z-index: -2;
            left: 62%;
        }

        & p {
            position: absolute;
            top: 70%;
            left: 68%;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            color: #F6F6F6;
            font-family: "Nurito", sans-serif;
            font-weight: 600;
        }
    }
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    & h3 {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        color: #1F0441;
        margin-bottom: 70px;
        z-index: 0;
        font-family: "Nurito", sans-serif;
        font-weight: 600;

        & a {
            text-decoration: underline;
            cursor: pointer !important;
        }
    }

    & p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #1F0441;
        text-align: center;
        font-family: "Nurito", sans-serif;
    }

    & img {
        position: absolute;
        top: -12%;
        max-width: 1440px;
        width: 100%;
    }
}



@media (max-width: 1024px) {

    .cluster {
        & .left {
       
            & img {
                position: absolute;
                top: 13.5%;
                left: 2%;
            }
            & p {
                position: absolute;
                top: 70%;
                left: 8%;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                color: #F6F6F6;
            }
        }
        & .center {

            & img {
                position: absolute;
                top: 13.5%;
                z-index: -1;
                left: 35%;
            }
    
            
            & p {
                position: absolute;
                top: 70%;
                left: 44%;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                color: #F6F6F6;
            }
        }
    
        & .right {

            & img {
                position: absolute;
                top: 13.5%;
                z-index: -2;
                left: 62%;
            }
    
            & p {
                position: absolute;
                top: 70%;
                left: 72%;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                color: #F6F6F6;
            }
        }
    }
   
}

@media (max-width: 1023px) {

    .cluster {
        display: none;
    }
    .link {
       
        & h3 {
            text-align: center;    
        }
    }
    
}

@media (max-width: 767px) {

    .clusterContainer {
        margin-bottom: 30px;
    }    
}

    
  
  