

nav {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    background: #f5f5f5;
    color: #fff;
    padding: 4px 0;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;

    & .logo {
        font-size: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        & img {
            width: 285px;
            height: 106px;
        }
    }

    & ul li {
        display: inline-block;
        list-style: none;
        margin: 5px 20px;
        font-size: 16px;
        color: #1F0441;
        font-weight: 400;
        line-height: 24px;
        margin: 0 5px  !important;
        
        & a {
            font-family: "Nurito", sans-serif;
        }
    }

    .btn {
        font-family: "Roboto", sans-serif;
        font-weight: 800;
    }
}

// .active {
//     color: #78aeff;
    
// }

.dark-nav {
    background: #f5f5f5;
    transition: .5s;
}

.menu-icon {
    display: none;
}

@media(max-width: 1210px) {
    nav {
        padding: 0 10px !important;
        justify-content: space-around;
        
        & ul li {
         font-size: 14px;
         margin: 5px 10px;
        }

        .btn {
            font-size: 14px;
            padding: 12px 16px;
        }

        & ul :nth-child(6) {
           font-size: 10px !important;
        }
    }
    

    nav ul li {
        margin: 10px 15px;
    }
}

@media(max-width: 1023px) {
    nav {
        padding: 15px 0;
        justify-content: space-between;

        & ul {
            position: fixed;
            top: 0;
            right: 0;
            background: #212ea0;
            z-index: -1;
            width: 200px;
            padding-top: 200px;
            transition: .5s;
            bottom: 0;
            height: 100vh;
        

            & li {
                display: block;
                margin: 25px 40px;
                text-align: center;
                color: #f5f5f5;
                font-size: 16px;
                margin-bottom: 20px !important;
               
            }


           
        }
    }
    
    .logo {
        z-index: -2;
    }

    .menu-icon {
        display: block;
        width: 30px;
        cursor: pointer;
        color: #1F0441;
    }

    .hide-mobile-menu {
        right: -200px;
    }
}
