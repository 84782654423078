.footer {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;

    & .telBlock {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        & .phoneNum {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 25px;
            margin-bottom: 20px;

            & .tel {
                color: #1F0441;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                font-family: "Nurito", sans-serif;
                
            }
        }

        & .btnBlock {
            margin-top: 30px;
        }
    }

    & .list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & ul {
            list-style: none;

            & li {
                margin-bottom: 20px;
                color: #1F0441;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                
                & a {
                    font-family: "Nurito", sans-serif;
                    font-weight: 400;
                }
            }
        }
    }

    & .logoBlock {

        & img {
            max-width: 347px;
            width: 100%;
        }

        & p {
            font-size: 12px;
            font-weight: 300;
            color: #1F0441;
            font-family: "Roboto", sans-serif;
        }
    }
}

@media (max-width: 1023px) {
    .footer {
       
        & ul {
          display: none;
        }

        & .logoBlock {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column  ;
        }
    }
}


@media(max-width: 767px) {
    
    .footer {
       
       display: flex;
       flex-direction: column;

       .logoBlock img {
        display: none;
       }
       
       .btn {
        margin-bottom: 20px;
       }
        
    }
}